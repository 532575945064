import * as React from 'react'
import Img from 'gatsby-image'

import rehypeReact from 'rehype-react'
const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

import { IPhone } from './iPhone'

export interface ProjectData {
  image?: any
  video?: any[]
  title: string
  htmlAst: any
}

export interface ProjectProps {
  project: ProjectData
}

export const Project: React.SFC<ProjectProps> = ({ project }) => (
  <article>
    <h3>{project.title}</h3>
    <div className="content">{renderAst(project.htmlAst)}</div>
    <IPhone>
      {project.image && <Img {...project.image} alt="Skärmbild" />}
      {project.video && (
        <video
          loop
          autoPlay
          muted
          playsInline
          preload="auto"
          aria-label="screencast"
        >
          {project.video.map(({ publicURL }) => (
            <source key={publicURL} src={publicURL} />
          ))}
        </video>
      )}
    </IPhone>
  </article>
)
